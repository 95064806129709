import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "why-we-go-off-chain"
    }}>{`Why we go off-chain`}</h1>
    <hr></hr>
    <p>{`If you have played some crypto games before, entering Farming Season for the first time might feel a bit odd to you. Why not waiting on this and that? No approval required for each action? And yes, you may later understand that it happens because the game is not fully on-chain.`}</p>
    <p>{`We want Farming Season to be friendly to new crypto players as much as possible. The game couldn't be much enjoyable if every action requires gas fee. So we decided to keep some data off-chain. Doing so decreases degree of security you normally have with on-chain games. On the other hand, going off-chain gives a much better experience to our players. After you have entered the game, you can be sure that Metamask will not get into your way anymore`}</p>
    <p>{`To make it clear, anything on-chain is done in the website. Actions like purchasing / depositing / withdrawing are done on chain. You can go to scanner website to check for each transaction`}</p>
    <p>{`Once entered the game, everything is off-chain. There is no blockchain confirmation during the play. So you can play the game on a browser without wallet like Metamask, and you can even play on mobile too!`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note. Without crypto wallet, you will need to enter the game with username and password which can be set in the dashboard.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      